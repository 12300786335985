// @ts-ignore
import twitterImage from "./images/banner_twitter.png";
// @ts-ignore
import defaultImage from "./images/banner.png";

export enum GeneratorMode {
  DEFAULT = "DEFAULT",
  TWITTER = "TWITTER",
}

export type GeneratorConfig = {
  width: number;
  height: number;
  backgroundImage: string;
};

const generatorConfigMap: Map<GeneratorMode, GeneratorConfig> = new Map([
  [
    GeneratorMode.DEFAULT,
    { height: 384, width: 1152, backgroundImage: defaultImage },
  ],
  [
    GeneratorMode.TWITTER,
    { width: 1024, height: 512, backgroundImage: twitterImage },
  ],
]);

export class BannerCanvas {
  canvasImageBackgroundContainer: HTMLDivElement;
  canvasImageBackground?: HTMLImageElement;

  canvasPreviewContainerElement!: HTMLDivElement;
  canvasPreviewElement?: HTMLCanvasElement;

  config!: GeneratorConfig;

  constructor(mode: GeneratorMode, baseText?: string) {
    const config = generatorConfigMap.get(mode);

    if (!config) {
      throw new Error(`no config found for key ${mode}`);
    }

    this.config = config;

    this.canvasPreviewContainerElement =
      this.getCanvasPreviewContainerElement();

    this.canvasImageBackgroundContainer =
      this.getCanvasImageBackgroundContainerElement();

    this.canvasPreviewElement = this.buildCanvasPreviewElement();

    this.canvasImageBackground = this.buildImageBackgroundElement();

    if (baseText) {
      this.updateText(baseText);
    }
  }

  async generatePNGWithText(text: string, filename = "hsv.png") {
    let canvas = document.createElement("canvas");
    canvas.height = this.config.height;
    canvas.width = this.config.width;

    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("cannot build context during png generation");
    }

    const img = await new Promise<HTMLImageElement>((res, rej) => {
      const img = document.createElement("img");
      img.src = this.config.backgroundImage;

      img.addEventListener("load", () => {
        res(img);
      });

      img.addEventListener("error", (error) => {
        rej(error);
      });
    });

    context.drawImage(img, 0, 0);

    this.drawTextOnto2DContext(text, context);

    canvas.toBlob((blob) => {
      if (blob === null) {
        return;
      }
      let data = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = data;
      link.download = filename;
      link.click();
    }, "image/png");

    img.remove();
    canvas.remove();
  }

  updateText(text: string) {
    this.resetPreviewCanvas();

    const context = this.getCanvasPreviewElementOrThrow().getContext("2d");

    if (!context) {
      throw new Error("cannot get canvas context");
    }

    this.drawTextOnto2DContext(text, context);
  }

  destroy() {
    this.getCanvasPreviewElementOrThrow().remove();
    this.getCanvasImageBackgroundElementOrThrow().remove();
  }

  private show() {
    this.getCanvasPreviewElementOrThrow().hidden = false;
  }

  private hide() {
    this.getCanvasPreviewElementOrThrow().hidden = true;
  }

  private drawTextOnto2DContext(
    text: string,
    context: CanvasRenderingContext2D,
  ) {
    let parts: string[] = text.split(String.fromCharCode(10));

    context.font = "56px Righteous";

    for (let i = 0; i < parts.length; i++) {
      context.fillText(parts[i], 340, 180 + i * 64);
    }
  }

  private buildCanvasPreviewElement() {
    if (this.canvasPreviewElement !== undefined) {
      throw new Error("canvas is already built");
    }

    let canvas = document.createElement("canvas");
    canvas.height = this.config.height;
    canvas.width = this.config.width;

    this.canvasPreviewContainerElement.appendChild(canvas);

    return canvas;
  }

  private buildImageBackgroundElement() {
    if (this.canvasImageBackground !== undefined) {
      throw new Error("canvas image background is already built");
    }

    let img = document.createElement("img");
    img.src = this.config.backgroundImage;

    this.canvasImageBackgroundContainer.appendChild(img);

    return img;
  }

  private getCanvasPreviewElementOrThrow() {
    if (this.canvasPreviewElement) return this.canvasPreviewElement;

    throw new Error("canvas isn't initialized.");
  }

  private getCanvasImageBackgroundElementOrThrow() {
    if (this.canvasImageBackground) return this.canvasImageBackground;

    throw new Error("canvas image background isn't initialized.");
  }

  private getCanvasPreviewContainerElement() {
    const container = document.getElementById("canvas-container");

    if (!(container instanceof HTMLDivElement)) {
      throw new Error("canvas-container no found by html id");
    }

    return container;
  }

  private getCanvasImageBackgroundContainerElement() {
    const container = document.getElementById("image-background-container");

    if (!(container instanceof HTMLDivElement)) {
      throw new Error("image-background-container no found by html id");
    }

    return container;
  }

  private resetPreviewCanvas() {
    const context = this.getCanvasPreviewElementOrThrow().getContext("2d");

    context?.clearRect(0, 0, this.config.width, this.config.height);
  }
}
