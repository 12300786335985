import { BannerCanvas, GeneratorMode } from "./canvas";

let lastInput: string = "";
const urlParams = new URLSearchParams(window.location.search);
let mode: GeneratorMode =
  urlParams.get("mode") === "twitter"
    ? GeneratorMode.TWITTER
    : GeneratorMode.DEFAULT;
let canvas: BannerCanvas = new BannerCanvas(mode);

const switchMode = (_mode: GeneratorMode) => {
  canvas.destroy();

  mode = _mode;
  canvas = new BannerCanvas(mode, lastInput);
};

const boot = async () => {
  let inputContainer = document.getElementById("input-container");
  if (inputContainer === null) {
    console.error("input container not found");
    return;
  }
  inputContainer.hidden = false;

  const input = document.getElementById("input");

  input?.addEventListener("input", async (event) => {
    if (!(event.target instanceof HTMLTextAreaElement)) {
      return;
    }

    const text = event.target.value.trim();

    canvas.updateText(text);

    lastInput = text;
  });

  const generate = async () => {
    await canvas.generatePNGWithText(lastInput);

    if (mode === GeneratorMode.TWITTER) {
      const params = new URLSearchParams({
        text: "I have contracted $HERPES, i'm sick of Love.",
        url: "https://getherp.es",
        hashtags: "KASPA,KRC20,HERPES",
        via: "HERPESKRC20",
      });
      window.open(
        "https://twitter.com/intent/tweet/?" + params.toString(),
        "_blank",
      );
    }
  };

  document.getElementById("generate")?.addEventListener("click", generate);

  document
    .getElementById("swap-default")
    ?.addEventListener("click", (_event) => {
      switchMode(GeneratorMode.DEFAULT);
    });

  document
    .getElementById("swap-twitter")
    ?.addEventListener("click", (_event) => {
      switchMode(GeneratorMode.TWITTER);
    });
};

document.addEventListener("DOMContentLoaded", function () {
  boot();
});
